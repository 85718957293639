<template>
    <div class="territory">
        <div class="territory__hero-block">
            <div class="u-relative">
                <h1 class="territory__title">Территории<br>университета</h1>
                <z-breadcrumbs theme="light"></z-breadcrumbs>
            </div>
        </div>
        <section class="territory__container territory-item row">
            <div class="territory-header__promo-text col-default-8 col-v-tablet-12">
                <z-caption tag="h2" size="4xl" weight="700">Корпоративный университет&nbsp;&mdash; digital и&nbsp;физическая среда обучения для непрерывного развития на&nbsp;протяжении всей жизни</z-caption>
                <p class="territory-item__text">
                    Мы&nbsp;практикуем экосистемный подход. Это гибкое персонализированное обучение на&nbsp;протяжении всей жизни. Его преимущества в&nbsp;том, что руководители получают вовлеченных профессионалов, способных достигать высоких результатов, используя свои знания и&nbsp;навыки эффективного взаимодействия. А&nbsp;сотрудники достигают профессиональных и&nbsp;карьерных целей, самореализовываются и&nbsp;раскрывают свой потенциал.
                </p>
                <p class="territory-item__text">
                    Корпоративный университет обладает всей необходимой инфраструктурой для обучения и&nbsp;развития сотрудников &laquo;Норникеля&raquo;. Подробнее о&nbsp;территории университета вы&nbsp;можете прочитать на&nbsp;этой странице.
                </p>
                <z-blockquote
                    :author="{
                        name: contacts.rector ? contacts.rector.name : '',
                        info: contacts.rector ? contacts.rector.description.value.TEXT : '',
                        photo: contacts.rector ? contacts.rector.previewPicture.link : ''
                    }"
                >
                </z-blockquote>
            </div>
            <div class="territory-item__aside col-default-3 col-v-tablet-12">
                <z-caption tag="h3" size="xl" weight="600">Города присутствия Корпоративного университета</z-caption>
                <modal-map/>
            </div>
        </section>
        <section class="territory__container territory-item white-bg">
            <section class="territory-item__wrapper row">
                <div class="territory-item__text col-default-8 col-v-tablet-12">
                    <z-caption tag="h2" size="4xl" weight="700">Корпоративный университет в&nbsp;Норильске</z-caption>
                    <p class="territory-item__text">
                        В&nbsp;нашем арсенале лицензия на&nbsp;осуществление профессионального обучения и&nbsp;дополнительного профессионального образования.
                    </p>
                    <p class="territory-item__text">
                        Инновационные и&nbsp;иммерсивные технологии обучения: виртуальная реальность (VR), тренажеры-симуляторы, 3D-атласы, мультимедийные курсы и&nbsp;т.&nbsp;д. А&nbsp;также собственная платформа дистанционного обучения и&nbsp;учебный подземный полигон.
                    </p>
                    <div class="territory-item__key-wrapper">
                        <z-key-indicator value="85" text-after="лет">
                            <template v-slot:detail>
                                в&nbsp;области профессионального образования
                            </template>
                        </z-key-indicator>
                        <z-key-indicator value="700" text-before=">" text-after="образовательных <br/> программ">
                            <template v-slot:detail>
                                для руководителей, специалистов и&nbsp;рабочих
                            </template>
                        </z-key-indicator>
                    </div>
                    <p>
                        Учебный подземный полигон&nbsp;КУ оборудован на&nbsp;базе технологических выработок шахты &laquo;Ангидрит&raquo; рудника &laquo;Кайерканский&raquo; в&nbsp;2012&nbsp;году. Его основная задача&nbsp;&mdash; обучение персонала, работников Заполярного филиала и&nbsp;других структур по&nbsp;специальностям &laquo;Машинист ПДМ&raquo; и&nbsp;&laquo;Бурильщик шпуров&raquo;.
                    </p>
                    <p>В&nbsp;2023 году на&nbsp;учебно-производственном комплексе&nbsp;КУ в&nbsp;Талнахе открыли тренажёрный комплекс для обучения безопасным методам и&nbsp;приемам выполнения работ на&nbsp;высоте и&nbsp;в&nbsp;ограниченных замкнутых пространствах (ОЗП). Он&nbsp;позволяет отрабатывать разнообразные приемы перемещения на&nbsp;высоте, предусмотренные действующими правилами охраны труда, включая системы канатного доступа.</p>
                </div>
                <div class="territory-item__aside col-default-3 col-v-tablet-12">
                    <z-contact-card
                        v-if="contacts.norilsk"
                        :photo="contacts.norilsk ? contacts.norilsk.director.previewPicture.link : ''"
                        :title="contacts.norilsk ? contacts.norilsk.director.name : ''"
                        :subtitle="contacts.norilsk ? contacts.norilsk.director.description.value.TEXT : ''"
                        :address="contacts.norilsk.address"
                        :phone="contacts.norilsk.phone"
                        :schedule="contacts.norilsk.schedule"
                        :email="contacts.norilsk.email"
                    />
                </div>
            </section>
            <section>
                <z-tabs>
                    <template v-slot:label>
                        <z-tabs-label id="talnakhskaya">Корпус на&nbsp;Талнахской</z-tabs-label>
                        <z-tabs-label id="training-base">Учебно-производственный комплекс</z-tabs-label>
                        <z-tabs-label id="training-ground">Учебный подземный полигон</z-tabs-label>
                    </template>
                    <template v-slot:content>
                        <z-tabs-content id="talnakhskaya">
                            <slider :buttons="true" controls-placement="center">
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-1.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-2.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-3.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-4.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-5.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-6.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-7.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-8.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-9.jpeg"/>
                                </slide>
                            </slider>
                        </z-tabs-content>
                        <z-tabs-content id="training-base">
                            <slider :buttons="true" controls-placement="center">
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-16.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-17.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-18.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-19.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-20.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-21.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-22.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-23.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-24.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-25.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-26.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-27.jpeg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/norilsk-28.jpeg"/>
                                </slide>
                            </slider>
                        </z-tabs-content>
                        <z-tabs-content id="training-ground">
                            <slider :buttons="true" controls-placement="center">
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/people-learn.jpg"/>
                                </slide>
                                <slide>
                                    <z-image src="/images/territory/norilsk-images/people-in-work.jpg"/>
                                </slide>
                            </slider>
                        </z-tabs-content>
                    </template>
                </z-tabs>
            </section>
        </section>
        <section class="territory__container territory-item bg-10-bg">
            <section class="territory-item__wrapper row">
                <div class="territory-item__text col-default-8 col-v-tablet-12">
                    <z-caption tag="h2" size="4xl" weight="700">Филиал ЧОУ ДПО &laquo;Корпоративный университет &laquo;Норильский никель&raquo; в&nbsp;г.&nbsp;Мончегорске</z-caption>
                    <p>
                        18&nbsp;июня 2021 года&nbsp;&mdash; важный день для Корпоративного университета. Мы&nbsp;получили лицензию на&nbsp;осуществление образовательной деятельности в&nbsp;филиале ЧОУ ДПО &laquo;Корпоративный университет &laquo;Норильский никель&raquo; в&nbsp;Мончегорске.
                    </p>
                    <div class="territory-item__key-wrapper">
                        <z-key-indicator value="2021" text-after="год">
                            <template v-slot:detail>
                                получения лицензии на&nbsp;осуществление образовательной деятельности
                            </template>
                        </z-key-indicator>
                        <z-key-indicator value="200+" text-after="программ">
                            <template v-slot:detail>
                                профессиональной подготовки и&nbsp;развития навыков и&nbsp;компетенций
                            </template>
                        </z-key-indicator>
                    </div>
                    <p>
                        Корпоративный университет в&nbsp;Мончегорске участвует в&nbsp;организации обучающих мероприятий в&nbsp;рамках программ, проводимых в&nbsp;Кольской горно-металлургической компании: трудовые отряды школьников и&nbsp;&laquo;Профессиональный старт&raquo;, а&nbsp;также занимается организацией и&nbsp;проведением конкурсов профессионального мастерства по&nbsp;различным профессиям.
                    </p>
                    <p>
                        Филиал состоит из&nbsp;3-х отделов в&nbsp;Мончегорске и&nbsp;Заполярном: отдел обеспечения деятельности, отдел по&nbsp;учебной работе и&nbsp;развитию персонала и&nbsp;отдел организации и&nbsp;сопровождения обучения.
                    </p>
                </div>
                <div class="territory-item__aside col-default-3 col-v-tablet-12">
                    <z-contact-card
                        :photo="contacts.monchegorsk ? contacts.monchegorsk.director.previewPicture.link : ''"
                        :title="contacts.monchegorsk ? contacts.monchegorsk.director.name : ''"
                        :subtitle="contacts.monchegorsk ? contacts.monchegorsk.director.description.value.TEXT : ''"
                        :address="contacts.monchegorsk ? contacts.monchegorsk.address : ''"
                        :phone="contacts.monchegorsk ? contacts.monchegorsk.phone : ''"
                        :schedule="contacts.monchegorsk ? contacts.monchegorsk.schedule : ''"
                        :email="contacts.monchegorsk ? contacts.monchegorsk.email : ''"
                    />
                </div>
            </section>
            <!-- <slider loop :buttons="true" controls-placement="center">
                <slide>
                    <z-image src="/images/territory/monchegorsk-images/building.jpg"/>
                    <p class="text-size-s">Главный корпус филиала Корпоративного Университета в&nbsp;Мончегорске</p>
                </slide>
                <slide>
                    <z-image src="/images/territory/monchegorsk-images/auditory.jpg"/>
                </slide>
                <slide>
                    <z-image src="/images/territory/monchegorsk-images/hall.jpg"/>
                </slide>
            </slider> -->
        </section>
        <section class="territory__container territory-item bg-5-bg">
            <section class="territory-item__wrapper row">
                <div class="territory-item__text col-default-8 col-v-tablet-12">
                    <z-caption tag="h2" size="4xl" weight="700">Представительство Корпоративного университета в&nbsp;Москве</z-caption>
                    <p>
                        Команда представительства осуществляет общее руководство проектной деятельностью Учреждения по&nbsp;различным направлениям.
                    </p>
                    <p>
                        В&nbsp;их&nbsp;числе реализация проектов в&nbsp;области повышения операционной эффективности бизнес-процессов и&nbsp;автоматизации бизнес-процессов в&nbsp;SAP LSO, формирования системы оценки и&nbsp;развития профессиональных компетенций.
                    </p>
                    <p>
                        Проводится большая работа по&nbsp;выстраиванию партнерских отношений с&nbsp;ВУЗами и&nbsp;СУЗами, развитию системы дополнительного профессионального образования и&nbsp;профориентации молодежи. Осуществляется координация работы по&nbsp;реализации целевых комплексных программ обучения и&nbsp;развития работников Компании.
                    </p>
                    <p>
                        Сотрудники представительства участвуют в&nbsp;стратегическом планировании деятельности Корпоративного университета и&nbsp;реализации программ трансформации, осуществляют организационно-методическую поддержку заказчиков обучения в&nbsp;инновационных образовательных инициативах и&nbsp;участвуют во&nbsp;внедрении единой системы аналитики по&nbsp;обучению и&nbsp;развитию персонала.
                    </p>
                </div>
                <div class="territory-item__aside col-default-3 col-v-tablet-12">
                    <z-contact-card
                        :photo="contacts.moscow ? contacts.moscow.director.previewPicture.link : ''"
                        :title="contacts.moscow ? contacts.moscow.director.name : ''"
                        :subtitle="contacts.moscow ? contacts.moscow.director.description.value.TEXT : ''"
                        :address="contacts.moscow ? contacts.moscow.address : ''"
                        :phone="contacts.moscow ? contacts.moscow.phone : ''"
                        :schedule="contacts.moscow ? contacts.moscow.schedule : ''"
                        :email="contacts.moscow ? contacts.moscow.email : ''"
                    />
                </div>
            </section>
        </section>
    </div>
</template>

<script>
import {mixinDevice} from '@/utils/mixin'
import ModalMap from './components/ModalMap'

export default {
    name: 'territory',
    components: {
        ModalMap
    },
    mixins: [mixinDevice],
    data () {
        return {
            contacts: this.$root.app.components.contacts
        }
    },
    computed: {
        horizontal () {
            return this.device === 'v-tablet' || this.device === 'mobile'
        }
    }
}
</script>

<style lang="scss">
.territory {
    @include margin-level('left', 2XL, true);
    @include margin-level('right', 2XL, true);

    .z-key-indicator__value-text--before {
        // font-size: 54px;
        font-size: var(--fontSize5xl);
    }

    &__title {
        font-weight: 700;
        line-height: 1.2;
        color: $token-colors-white;
    }

    &__hero-block {
        border-radius: 20px;
        padding: 74px;
        background-color: $token-colors-primary;
        background-image: url("/images/territory/hero-image.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        max-height: 600px;
        @include margin-level('left', 2XL);
        @include margin-level('right', 2XL);
        // @include margin-level('top', 2XL, true);
        position: relative;

        &:before {
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: black;
            opacity: .6;
            position: absolute;
            border-radius: 20px;
        }

        @include breakpoint(mobile) {
            padding: 20px;
        }

        .z-breadcrumbs {
            margin-bottom: 348px;

            .z-breadcrumbs__item:not(.is-current) .z-breadcrumbs__link {
                color: $token-colors-white;
            }

            .z-breadcrumbs__item:not(.is-current):after {
                color: $token-colors-black-40;
            }
        }
    }

    &__container {
        padding: 80px;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;

        &:last-child {
            .territory-item__text {
                margin-bottom: 0;
            }
        }
    }

    .z-blockquote {
        padding: 0;
        background-color: transparent;
    }

    .slider .swiper-slide.swiper-slide-active p {
        @include breakpoint(v-tablet) {
            width: 100%;
            transform: translateX(0%);
            padding: 0;
        }
    }

    .slider__controls {
        @include breakpoint(tablet) {
            top: calc(50vw*0.65*0.25 + 50vw*0.32*1.25)
        }

        @media (max-width: 1023px) {
            top: calc(50vw*0.65*0.25 + 50vw*0.47*1.25)
        }

        @media (max-width: 800px) {
            top: calc(50vw*0.65*0.25 + 50vw*0.46*1.25)
        }

        @media (max-width: 700px) {
            top: calc(50vw*0.65*0.25 + 50vw*0.44*1.25)
        }

        @media (max-width: 599px) {
            top: calc(50vw*0.65*0.25 + 50vw*0.51*1.25)
        }

        @media (max-width: 450px) {
            top: calc(50vw*0.65*0.25 + 50vw*0.47*1.25)
        }
    }
}

.territory-item {
    border-radius: 20px 20px 0 0;

    &:not(:first-child) {
        margin-top: -20px;
    }

    &:not(:last-child) {
        padding-bottom: 80px;
    }

    &:last-child {
        padding-bottom: 0;
    }

    &__aside {
        margin-left: auto;

        @include breakpoint(tablet) {
            margin-left: $token-spacers-s;
        }

        @include breakpoint(mobile) {
            margin-left: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            background: #EFF6FB;
            display: block;
        }
    }

    .z-caption {
        color: $token-colors-black-80;
        margin-bottom: $token-spacers-s;

        &--aside {
            font-weight: 600;
            // font-size: 20px;
            font-size: var(--fontSizeXl);
            line-height: 1.3;
        }
    }

    &__text {
        font-weight: 400;
        // font-size: 20px;
        font-size: var(--fontSizeXl);
        line-height: 130%;
        color: $token-colors-black-70;
        margin-bottom: $token-spacers-s;
    }

    &__key-wrapper {
        display: flex;
        margin-bottom: $token-spacers-s;

        .z-key-indicator {
            @include margin-level('right', 2XL);

            @include breakpoint(mobile) {
                margin-top: $token-spacers-2-xs;
            }
        }

        @include breakpoint(mobile) {
            flex-wrap: wrap;
        }
    }
}

</style>
