<template>
<div :class="[
    'z-input',
    {
        'z-input--filter': isFilter,
        'z-input--clearable': isClearable,
        'is-focused': isFocused,
        'is-errored': isValid === false,
        'is-valid': isValid && !isFilter,
        'is-filled': inputValue !== '' && typeof inputValue !== 'object',
        'is-disabled': disabled,
        'is-required': required
    }
]">
    <div class="z-input__wrapper">
        <label class="z-input__label">
            <p
                class="z-input__title"
                v-if="title"
                v-html="title"
            ></p>
            <div class="z-input__container">
                <input
                    :alt="alt"
                    class="z-input__input"
                    name="name"
                    type="text"
                    v-bind="$attrs"
                    @blur="onBlur"
                    @focus="onFocus"
                    @input="onInput($event)"
                    @change="onChange($event)"
                    v-mask="mask"
                    v-model="inputValue"
                    :disabled="disabled"
                    @keypress="onKeyPress"
                >
                <span
                    v-show="placeholder"
                    v-html="placeholder"
                    class="z-input__placeholder"
                ></span>
            </div>
        </label>
        <span
            v-if="isClearable && inputValue"
            class="z-input__clear"
            @click="clear"
        ></span>
    </div>
    <span
        :class="[
            'z-input__error',
            errorClass
        ]"
        v-html="error"
        v-if="error && !isValid"
    ></span>
</div>
</template>

<script>
import { VueMaskDirective as mask } from 'v-mask'

export default {
    name: 'z-input',
    inheritAttrs: false,
    props: {
        alt: String,
        title: String,
        placeholder: String,
        value: [Number, String],
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            validator: prop => ['text', 'number', 'tel', 'email', 'link', 'text-only'].includes(prop),
            default: 'text'
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        mask: {
            type: String,
            default: ''
        },
        errorClass: {
            type: String,
            default: ''
        },
        validateOnBlur: {
            type: Boolean,
            default: true
        },
        positive: {
            type: Boolean,
            default: true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        isFilter: {
            type: Boolean,
            default: false
        },
        minVal: [String, Number],
        maxVal: [String, Number],
        fieldName: {
            type: String,
            default: 'Поле'
        },
        minLength: {
            type: Number
        },
        maxLength: {
            type: Number
        }
    },
    directives: {
        mask
    },
    data () {
        return {
            localValue: null,
            isFocused: false,
            isValid: null,
            error: '',
            text: {
                errors: {
                    required: {
                        ru: 'Поле обязательно для заполнения',
                        en: 'Required field',
                        cn: '填项目'
                    },
                    invalid: {
                        email: {
                            ru: 'Формат для e-mail указан неверно',
                            en: 'Invalid email format',
                            cn: '无效的电子邮件格式'
                        },
                        url: {
                            ru: 'Неверный формат ссылки',
                            en: 'Invalid link format'
                        },
                        text: {
                            ru: 'Допускаются только буквы и спецсимволы',
                            en: 'Only letters and special characters are allowed',
                            cn: '只允许使用字母和特殊字符'
                        },
                        tel: {
                            ru: 'Допускаются только цифры и спецсимволы',
                            en: 'Only numbers and special characters are allowed',
                            cn: '只允许使用数字和特殊字符'
                        },
                        pattern: {
                            ru: 'Неверный формат данных',
                            en: 'Incorrect data format'
                        },
                        minVal: {
                            ru: `Минимальное допустимое значение - ${this.minVal}`,
                            en: `Minimum allowable value - ${this.minVal}`
                        },
                        maxVal: {
                            ru: `Максимальное допустимое значение - ${this.maxVal}`,
                            en: `Maximum allowable value - ${this.maxVal}`
                        },
                        minLength: {
                            ru: `${this.fieldName} должно содержать не менее ${this.minLength} символов.`,
                            en: `${this.fieldName} must contain at least ${this.minLength} characters.`
                        },
                        maxLength: {
                            ru: `${this.fieldName} не должно превышать ${this.maxLength} символов.`,
                            en: `${this.fieldName} must not exceed ${this.maxLength} characters.`
                        }
                    }
                }
            }
        }
    },
    computed: {
        inputValue: {
            get () {
                if (this.value !== undefined) return this.value // для работы без v-model
                return this.localValue
            },
            set (value) {
                this.$emit('input', value)
                this.localValue = value
            }
        },
        isClearable () {
            if (this.isFilter) return true
            return this.clearable
        }

    },
    methods: {
        clearIncompletedField () { // может понадобится, пока не используется
            if (this.mask && this.inputValue) {
                if (this.inputValue.length < this.mask.length) {
                    this.$emit('input', '')
                }
            }
        },
        clear () {
            this.localValue = ''
            this.$emit('input', '')
            this.$emit('change', '')
            this.$nextTick(this.validate)
        },
        onBlur () {
            this.isFocused = false
            if (this.validateOnBlur) this.validate()
        },
        onFocus () {
            this.isFocused = true
        },
        onInput (e) {
            this.$emit('input', e.target.value)
            if (this.isValid !== null) this.$nextTick(this.validate)
        },
        onKeyPress (e) {
            if (this.type !== 'number') return
            if (this.positive && (e.charCode > 31 && (e.charCode < 48 || e.charCode > 57)) && e.charCode !== 46) {
                e.preventDefault()
            }
        },
        onChange (e) {
            this.$emit('change', e.target.value)
        },
        validateEmail (email) {
            // eslint-disable-next-line no-useless-escape
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return regex.test(email)
        },
        validateTel (tel) {
            // eslint-disable-next-line no-useless-escape
            const regex = /^[0-9\(\)\/ +_@.-]*$/
            return regex.test(tel)
        },
        validateUrl (url) {
            // eslint-disable-next-line no-useless-escape
            const regex = /(^https?:\/\/)?[а-яa-z0-9~_\-\.]+\.[а-яa-z]{2,9}(\/|:|\?[!-~]*)?$/i
            return regex.test(url)
        },
        validateTextOnly (str) {
            // eslint-disable-next-line no-useless-escape
            const regex = /^[a-zA-Zа-яА-Я\s]*$/
            return regex.test(str)
        },
        validatePattern () {
            return this.inputValue.length === this.mask.length
        },
        validate () {
            if (this.required && !(this.inputValue !== '' && typeof this.inputValue !== 'object')) {
                this.isValid = false
                this.error = this.$root.localize(this.text.errors.required)
                return
            }

            if (this.type === 'email' && !this.validateEmail(this.inputValue)) {
                this.isValid = false
                this.error = this.$root.localize(this.text.errors.invalid.email)
                return
            }

            if (this.type === 'link' && !this.validateUrl(this.inputValue)) {
                this.isValid = false
                this.error = this.$root.localize(this.text.errors.invalid.url)
                return
            }

            if (this.type === 'text-only' && !this.validateTextOnly(this.inputValue)) {
                this.isValid = false
                this.error = this.$root.localize(this.text.errors.invalid.text)
                return
            }

            if (this.type === 'tel' && !this.validateTel(this.inputValue)) {
                this.isValid = false
                this.error = this.$root.localize(this.text.errors.invalid.tel)
                return
            }

            if (this.mask && !this.validatePattern()) {
                this.isValid = false
                this.error = this.$root.localize(this.text.errors.invalid.pattern)
                return
            }

            if (this.type === 'number') {
                if (this.minVal && Number(this.inputValue) < Number(this.minVal)) {
                    this.isValid = false
                    this.error = this.$root.localize(this.text.errors.invalid.minVal)
                    return
                }

                if (this.maxVal && Number(this.inputValue) > Number(this.maxVal)) {
                    this.isValid = false
                    this.error = this.$root.localize(this.text.errors.invalid.maxVal)
                    return
                }
            }

            if (this.required && this.minLength && (this.inputValue.length < this.minLength)) {
                this.isValid = false
                this.error = this.$root.localize(this.text.errors.invalid.minLength)
                return
            }

            if (this.required && this.maxLength && (this.inputValue.length > this.maxLength)) {
                this.isValid = false
                this.error = this.$root.localize(this.text.errors.invalid.maxLength)
                return
            }

            (this.inputValue !== '' && typeof this.inputValue !== 'object') ? this.isValid = true : this.isValid = null
            this.error = ''
        }
    }
}
</script>

<style lang="scss">
$clearColorDefault: '%23A1A7B5';
$clearColorHover: '%23232323';

.z-input {
    $parent: &;
    font-size: var(--textSize);
    width: 100%;
    font-weight: 400;

    &__container {
        position: relative;
    }

    &__wrapper {
        position: relative;
    }

    &__clear {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        width: var(--iconSize);
        height: var(--iconSize);
        margin-top: 0;
        right: var(--clearRight);
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.0493 4.79926C16.2836 4.56495 16.2836 4.18505 16.0493 3.95074C15.8149 3.71642 15.4351 3.71642 15.2007 3.95074L10 9.15147L4.79926 3.95074C4.56495 3.71642 4.18505 3.71642 3.95074 3.95074C3.71642 4.18505 3.71642 4.56495 3.95074 4.79926L9.15147 10L3.95074 15.2007C3.71642 15.4351 3.71642 15.8149 3.95074 16.0493C4.18505 16.2836 4.56495 16.2836 4.79926 16.0493L10 10.8485L15.2007 16.0493C15.4351 16.2836 15.8149 16.2836 16.0493 16.0493C16.2836 15.8149 16.2836 15.4351 16.0493 15.2007L10.8485 10L16.0493 4.79926Z' fill='#{$clearColorDefault}'/%3E%3C/svg%3E%0A");

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.0493 4.79926C16.2836 4.56495 16.2836 4.18505 16.0493 3.95074C15.8149 3.71642 15.4351 3.71642 15.2007 3.95074L10 9.15147L4.79926 3.95074C4.56495 3.71642 4.18505 3.71642 3.95074 3.95074C3.71642 4.18505 3.71642 4.56495 3.95074 4.79926L9.15147 10L3.95074 15.2007C3.71642 15.4351 3.71642 15.8149 3.95074 16.0493C4.18505 16.2836 4.56495 16.2836 4.79926 16.0493L10 10.8485L15.2007 16.0493C15.4351 16.2836 15.8149 16.2836 16.0493 16.0493C16.2836 15.8149 16.2836 15.4351 16.0493 15.2007L10.8485 10L16.0493 4.79926Z' fill='#{$clearColorHover}'/%3E%3C/svg%3E%0A");
        }
    }

    &__label {
        display: block;
    }

    &__input {
        border-radius: var(--borderRadius);
        border: var(--formBorderWidth) solid var(--formBorderColorDefault);
        width: 100%;
        color: var(--formTextColorDefault);
        box-sizing: border-box;
        padding: var(--fieldPaddingM);
        transition: border-color 0.2s ease-in;
        line-height: 1.4;
        height: var(--fieldHeightM);
        background: var(--fieldBg);

        // обнуления стиля для автозаполненных полей
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px white inset !important;
            transition: 0s;
        }
    }

    &__placeholder {
        padding: 0px 4px;
        display: block;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--formPlaceholderColorDefault);
        background-color: transparent;
        pointer-events: none;
        overflow: hidden;
        transform: translateY(var(--placeholderTranslateY)) scale(1);
        transition: all .3s ease-in-out;
        font-size: 1em;
        line-height: 1;
        margin-top: 0;
    }

    &__error {
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        position: relative;
        margin-left: 20px;
        display: block;
        line-height: 1.2;
        margin-top: 4px;
    }

    // hover state
    &:hover {
        #{$parent}__input {
            border-color: var(--formBorderColorHover);
        }
    }

    // filled state
    &.is-filled {
        #{$parent}__placeholder {
            width: auto;
            transform: translateY(var(--placeholderFilledTranslateY));
            left: var(--placeholderFilledLeft);
            font-size: var(--filledPlaceholderTextSize);
            line-height: 1.2;
            background-color: var(--placeholderBg);
        }

        #{$parent}__input {
            border-color: var(--formBorderColorFocus);
        }
    }

    // errored state
    &.is-errored {
        #{$parent}__input {
            border-color: var(--formBorderColorError);
        }
    }

     // valid state
    &.is-valid {
        #{$parent}__input {
            border-color: var(--formBorderColorValid);
        }
    }

    // focused state
    &.is-focused {
        #{$parent}__placeholder {
            width: auto;
            transform: translateY(var(--placeholderFilledTranslateY));
            font-size: var(--filledPlaceholderTextSize);
            line-height: 1.2;
            left: var(--placeholderFilledLeft);
            background-color: var(--placeholderBg);
        }

        #{$parent}__input {
            border-color: var(--formBorderColorFocus);
        }
    }

    // disabled state
    &.is-disabled {
        pointer-events: none;
    }

    &--clearable {
        #{$parent}__input {
            padding-right: calc(var(--clearRight) + var(--iconSize) + 8px);
        }
    }

    &--filter {
        // filled state
        &.is-filled {
            #{$parent}__input {
                border-color: var(--formBorderColorFilledAccent);
            }
        }
    }

    &.is-required {
        .z-input__placeholder {
            &:after {
                content: '*';
                color: var(--errorTextColor);
                margin-left: 4px;
            }
        }

        .z-input__error {
            &:before {
                content: '*';
                color: var(--errorTextColor);
                margin-right: 4px;
            }
        }
    }
}
</style>
