<template>
    <div class="z-checkbox-group" v-if="data.length">
      <div class="z-checkbox-group__list">
        <div
          :class="[
            'z-checkbox z-checkbox-group__item',
            { 'is-error': error },
            `z-checkbox--size-${size}`
          ]"
          v-for="(item, idex) in data"
          :key="idex"
        >
          <label class="z-checkbox__label">
            <input
              class="z-checkbox__input"
              type="checkbox"
              v-model="groupValue"
              :value="item.value"
              @change="onChange"
            >
            <span class="z-checkbox__box"></span>
            <span class="z-checkbox__text">
                <vue-raw :raw="item.text" />
            </span>
          </label>
        </div>
      </div>
      <span
        :class="['z-checkbox-group__error', errorClass]"
        v-if="error"
        v-html="error"
        ></span>
    </div>
</template>

<script>
// localValue - для хранения значения группы, если компонент идет без привязки v-model
export default {
    name: 'ZCheckboxGroup',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        errorClass: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'm',
            validator: prop => ['s', 'm', 'l'].includes(prop)
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            localValue: this.value,
            error: '',
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field',
                    cn: '必填项目'
                }
            }
        }
    },
    computed: {
        groupValue: {
            get () {
                if (this.value) return this.value // для работы без v-model
                return this.localValue
            },
            set (value) {
                this.$emit('input', value)
                this.localValue = value
            }
        }
    },
    methods: {
        onChange () {
            this.$nextTick(this.validate)
            this.$emit('change', this.groupValue)
        },
        validate () {
            if (this.required && this.groupValue.length === 0) {
                this.error = this.$root.localize(this.text.error)
            } else {
                this.error = ''
            }
        }
    }
}
</script>

<style lang="scss">

$iconColor: '%230273C5';

.z-checkbox {
    $parent: &;

    font-size: var(--textSize);
    display: flex;

    &__label {
        display: inline-flex;
        cursor: pointer;

        &:hover {
            #{$parent}__box {
                border-color: var(--formBorderColorHover);
            }
        }
    }

    &__text {
        color: var(--formTextColorDefault);
        transition: color 0.2s ease-in;
        margin-left: 8px;
        font-size: var(--boxFontSizeM);
        font-weight: 400;
        line-height: 1.5;
    }

    &__box {
        display: block;
        width: var(--boxSizeM);
        height: var(--boxSizeM);
        border: var(--formBorderWidth) solid var(--formBorderColorDefault);
        border-radius: var(--borderRadius);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 0.9375em auto;
        transition: all 0.2s ease-in;
        flex-shrink: 0;
        cursor: pointer;
        margin: 0;
        background-color: var(--fieldBg);
    }

    &__input {
        display: none;

        &:checked {
            +#{$parent}__box {
                border-color: var(--formBorderColorFilledAccent);
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.2803 3.71783C10.5732 3.42494 10.5732 2.95006 10.2803 2.65717C9.98744 2.36428 9.51256 2.36428 9.21967 2.65717L4.59375 7.28309L2.78033 5.46967C2.48744 5.17678 2.01256 5.17678 1.71967 5.46967C1.42678 5.76256 1.42678 6.23744 1.71967 6.53033L4.06342 8.87408C4.35631 9.16697 4.83119 9.16697 5.12408 8.87408L10.2803 3.71783Z' fill='#{$iconColor}'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &--size-s {
        #{$parent}__box {
            width: var(--boxSizeS);
            height: var(--boxSizeS);
        }

        #{$parent}__text {
            font-size: var(--boxFontSizeS);
        }
    }

    &--size-m {
        #{$parent}__box {
            width: var(--boxSizeM);
            height: var(--boxSizeM);
        }

        #{$parent}__text {
            font-size: var(--boxFontSizeM);
        }
    }

    &--size-l {
        #{$parent}__box {
            width: var(--boxSizeL);
            height: var(--boxSizeL);
        }

        #{$parent}__text {
            font-size: var(--boxFontSizeL);
        }
    }

    // error state
    &.is-error {
        #{$parent}__box {
            border-color: var(--formBorderColorError);
            background-color: var(--formBgColorError);
        }
    }

    // disabled state
    &.is-disabled {
        pointer-events: none;

        #{$parent}__box {
            border-color: var(--formBorderColorDisabled);
            background-color: var(--formBgColorDisabled);
        }

        #{$parent}__text {
            color: var(--formTextColorDisabled);
        }
    }
}

.z-checkbox-group {
    overflow: hidden;

    &__list {
        margin-top: -16px;
    }

    &__item {
        margin-top: 16px;
    }

    &__error {
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        margin-top: 10px;
    }
}

</style>
